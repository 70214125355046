var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('sub-head', _vm._b({
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('tit-wrap-tertiary', {
          staticClass: "pb-0",
          scopedSlots: _vm._u([{
            key: "titHead",
            fn: function () {
              return [_vm._t("titHead")];
            },
            proxy: true
          }, {
            key: "tit",
            fn: function () {
              return [_vm._t("tit")];
            },
            proxy: true
          }], null, true)
        })];
      },
      proxy: true
    }])
  }, 'sub-head', Object.assign({
    tabsTag: _vm.tabsTag,
    tabsAttrs: _vm.tabsAttrs,
    tabTag: _vm.tabTag,
    tabAttrs: _vm.tabAttrs
  }, _vm.$attrs), false));

}
var staticRenderFns = []

export { render, staticRenderFns }