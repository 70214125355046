var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-sub-head-basic', _vm._b({
    attrs: {
      "tabsTag": "u-tabs-dot",
      "tabTag": "u-tab-dot",
      "tabsAttrs": {
        grow: false,
        centered: true,
        class: 'v-tabs--wide-border'
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.$slots, function (_, slotName) {
      return {
        key: slotName,
        fn: function () {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true)
  }, 'u-sub-head-basic', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }