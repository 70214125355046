var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-tit-wrap-default', _vm._b({}, 'u-tit-wrap-default', _vm.$attrs, false), [_c('u-tit-default', {
    staticClass: "tit--default tit--xl font-weight-semibold line-height-1",
    class: _vm.dark ? 'white--text' : 'grey--text text--darken-4'
  }, [_c('span', {
    class: _vm.white ? 'white--text' : 'primary--text',
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "600"
    }
  }, [_vm._t("titHead")], 2), _c('span', {
    attrs: {
      "data-aos": "zoom-out",
      "data-aos-delay": "400"
    }
  }, [_vm._t("tit")], 2)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }