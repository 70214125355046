<template>
    <sub-head v-bind="{ tabsTag, tabsAttrs, tabTag, tabAttrs, ...$attrs }">
        <template #title>            
            <tit-wrap-tertiary class="pb-0">
                <template #titHead><slot name="titHead" /></template>
                <template #tit><slot name="tit" /></template>
            </tit-wrap-tertiary>
        </template>
    </sub-head>
</template>

<script>
import SubHead from "@/sets/styles/pages/sub-head.vue";
import TitWrapTertiary from "@/components/publish/parents/typography/tit-wrap-tertiary.vue";

export default {
    props: {
        tabsTag: String,
        tabsAttrs: { type: Object, default: () => ({}) },
        tabTag: String,
        tabAttrs: { type: Object, default: () => ({}) },
    },
    components: {
        SubHead,
        TitWrapTertiary,
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped></style>
