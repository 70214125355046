<template>
    <u-sub-head-basic v-bind="$attrs" tabsTag="u-tabs-dot" tabTag="u-tab-dot" :tabsAttrs="{ grow: false, centered: true, class: 'v-tabs--wide-border' }">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-sub-head-basic>
</template>

<script>
import USubHeadBasic from "@/sets/styles/pages/u-sub-head-basic.vue";

export default {
    props: {},
    components: {
        USubHeadBasic,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-tabs {
        background-color: transparent !important;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    ::v-deep{
        .v-tabs {
            .v-tab{
                min-width: 240px;
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
</style>
