<template>
    <u-tit-wrap-default v-bind="$attrs">
        <u-tit-default :class="dark ? 'white--text' : 'grey--text text--darken-4'" class="tit--default tit--xl font-weight-semibold line-height-1">
            <span data-aos="zoom-out" data-aos-delay="600" :class="white ? 'white--text' : 'primary--text'"><slot name="titHead"/></span>
            <span data-aos="zoom-out" data-aos-delay="400"><slot name="tit" /></span>
        </u-tit-default>
    </u-tit-wrap-default>
</template>

<script>
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";

export default {
    props: {
        dark: { type: Boolean, default: false },
        white: { type: Boolean, default: false },
    },
    components: {
        UTitWrapDefault,
        UTitDefault,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.tit--default{
    >span{
        display: inline-block;
        width: fit-content;
    }
}
</style>