<template>
    <div class="sub-head">
        <div class="sub-visual">
            <div class="sub-visual__inner">
                <v-container v-if="this.$slots['title']">
                    <slot name="title" />
                </v-container>
            </div>
            <div class="sub-visual__bg">
                <div data-aos="zoom-out" class="sub-visual__bg__inner" style="background-image:url(/images/sub/sub-visual.jpg);"></div>
            </div>
        </div>

        <gnb v-if="tabActive" v-bind="{ itemTitle, tabsTag, tabsAttrs, tabTag, tabAttrs, tabActive }" gnbName="sub-tab" />
    </div>
</template>

<script>
import Gnb from "@/components/publish/styles/headers/gnb.vue";

export default {
    props: {
        title: String,
        itemTitle: String,
        tabsTag: String,
        tabsAttrs: { type: Object, default: () => ({}) },
        tabTag: String,
        tabAttrs: { type: Object, default: () => ({}) },
        tabActive: String,
    },
    components: {
        Gnb,
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.sub-head {
    ::v-deep {
        .v-tab--active {
            pointer-events: none;
        }
    }
    &--with-header {
        margin-top: calc(var(--header-height) * -1);
        .sub-visual {
            padding-top: var(--header-height);
        }
    }
    &--with-tabs {
        position: relative;
        .sub-visual {
            padding-bottom: var(--tab-height-comfortable);
        }
        .sub-tab-wrap {
            position: absolute;
            bottom: 0;
            left: 50%;
            -webkit-transform: translate(-50%, 0);
            -ms-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
            width: 100%;
        }
    }
}

.sub-visual {
    position: relative;
    overflow: hidden;
    &__inner {
        display: flex;
        align-items: center;
        height: var(--sub-visual-height);
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 50%;
        z-index: -1;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        width: 100vw;
        height: 100%;
        &__inner{
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
