var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sub-head"
  }, [_c('div', {
    staticClass: "sub-visual"
  }, [_c('div', {
    staticClass: "sub-visual__inner"
  }, [this.$slots['title'] ? _c('v-container', [_vm._t("title")], 2) : _vm._e()], 1), _vm._m(0)]), _vm.tabActive ? _c('gnb', _vm._b({
    attrs: {
      "gnbName": "sub-tab"
    }
  }, 'gnb', {
    itemTitle: _vm.itemTitle,
    tabsTag: _vm.tabsTag,
    tabsAttrs: _vm.tabsAttrs,
    tabTag: _vm.tabTag,
    tabAttrs: _vm.tabAttrs,
    tabActive: _vm.tabActive
  }, false)) : _vm._e()], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sub-visual__bg"
  }, [_c('div', {
    staticClass: "sub-visual__bg__inner",
    staticStyle: {
      "background-image": "url(/images/sub/sub-visual.jpg)"
    },
    attrs: {
      "data-aos": "zoom-out"
    }
  })]);

}]

export { render, staticRenderFns }